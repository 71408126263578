import {AnalyticsBrowser} from '@dreamdata/analytics-next'
import {createContext, useContext, useEffect, useState} from 'react'
import {loadDreamdata, loadUserled, reactToUsercentrics, UserledTracker} from './utils'
import {useEffectOnce} from 'react-use'

const UC_USER_INTERACTION_KEY = 'uc_user_interaction'
const UC_EVENT_TYPE = 'ucEvent'

interface UCEvent {
  detail: {action: string}
}

type AnalyticsContextType = {
  dreamdataTracker: AnalyticsBrowser | null
  userledTracker: UserledTracker | null
}

const AnalyticsContext = createContext<AnalyticsContextType | null>(null)

export const AnalyticsProvider = ({children}: {children: React.ReactNode}) => {
  const [dreamdataTracker, setDreamdataTracker] = useState<AnalyticsBrowser | null>(null)
  const [dreamdataLoaded, setDreamdataLoaded] = useState(false)
  const [userledTracker, setUserledTracker] = useState<UserledTracker | null>(null)

  // Listen to UC interactions and load Dreamdata accordingly
  useEffect(() => {
    if (!dreamdataTracker) {
      return
    }

    // If user has interacted with the banner before, load Services.
    if (localStorage.getItem(UC_USER_INTERACTION_KEY) === 'true') {
      loadDreamdata(dreamdataTracker, setDreamdataLoaded)
      loadUserled(setUserledTracker)
    }

    const loadDreamdataFn = () => loadDreamdata(dreamdataTracker, setDreamdataLoaded)
    const loadUserledFn = () => loadUserled(setUserledTracker)

    const handleUCEvent = (event: Event) =>
      reactToUsercentrics({
        event: event as unknown as UCEvent,
        dreamdataTracker,
        dreamdataLoaded,
        loadDreamdataFn,
        userledTracker,
        loadUserledFn,
      })

    window.addEventListener(UC_EVENT_TYPE, handleUCEvent as EventListener)

    return () => {
      window.removeEventListener(UC_EVENT_TYPE, handleUCEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run once to set listener
  }, [dreamdataTracker])

  useEffectOnce(() => {
    if (typeof window === 'undefined') {
      return
    }

    const analyticsBrowser = require('@dreamdata/analytics-next')
    const dreamDataAnalytics: AnalyticsBrowser = new analyticsBrowser.AnalyticsBrowser()
    setDreamdataTracker(dreamDataAnalytics)
  })

  return <AnalyticsContext.Provider value={{dreamdataTracker, userledTracker}}>{children}</AnalyticsContext.Provider>
}

export const useGetAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error('useGetAnalyticsContext must be used within an AnalyticsProvider')
  }

  return context
}
