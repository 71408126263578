import Head from 'next/head'
import {Fragment, memo, ReactNode, useMemo} from 'react'
const baseUrl = 'https://www.getmoss.com'

export const Meta = memo(function Meta(props: {
  author?: string
  date?: string
  description?: string
  keywords?: Array<string>
  slug?: string
  title?: string
  imageUrl?: string
  children?: ReactNode
  noIndex?: boolean
}) {
  const url = useMemo(() => `${baseUrl}${props.slug || ''}`, [props.slug])

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta property="og:type" content="website" />

      {props.title && (
        <Fragment>
          <title>{props.title}</title>
          <meta property="og:title" content={props.title} key="og:title" />
          <meta name="twitter:title" content={props.title} key="twitter:title" />
        </Fragment>
      )}

      {props.description && (
        <Fragment>
          <meta name="twitter:description" content={props.description} key="twitter:description" />
          <meta property="og:description" content={props.description} key="og:description" />
          <meta name="description" content={props.description} key="description" />
        </Fragment>
      )}

      {props.imageUrl && (
        <Fragment>
          <meta property="og:image" content={props.imageUrl} key="og:image" />
          <meta name="twitter:image" content={props.imageUrl} key="twitter:image" />
        </Fragment>
      )}

      <meta property="og:url" content={url} key="og:url" />
      <meta name="twitter:url" content={url} key="twitter:url" />
      {props.date && <meta property="article:published_time" content={props.date} />}
      <meta property="og:site_name" content={baseUrl} />

      {props.author && (
        <Fragment>
          <meta property="article:author" content={props.author} key="article:author" />
          <meta name="author" content={props.author} key="author" />
        </Fragment>
      )}

      {props.keywords && <meta name="keywords" content={(props.keywords ?? []).join(', ')} key="keywords" />}
      {props.noIndex && <meta name="robots" content="noindex" />}
      {props.children}
    </Head>
  )
})
