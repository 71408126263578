import {useEffect} from 'react'
import {useRouter} from 'next/router'
import {useGetAnalyticsContext} from 'src/context/AnalyticsContext'

export const OnRouteChangeTracker = () => {
  const {dreamdataTracker, userledTracker} = useGetAnalyticsContext()
  const router = useRouter()

  const trackPageEvent = () => {
    dreamdataTracker?.page()
    userledTracker?.page()
  }

  // Track page views with Dreamdata
  useEffect(() => {
    dreamdataTracker?.page()

    router.events.on('routeChangeComplete', trackPageEvent)

    return () => {
      router.events.off('routeChangeComplete', trackPageEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not need to run on every trackPageEvent change
  }, [router.events, dreamdataTracker])

  if (typeof window === 'undefined') {
    return null
  }

  return null
}
