import {PrismicPreview} from '@prismicio/next'
import {PrismicProvider} from '@prismicio/react'
import {AppProps} from 'next/app'
import localFont from 'next/font/local'
import Link from 'next/link'
import sm from 'slicemachine.config.json'
import 'src/assets/global.css'
import {CookiesProvider} from 'src/common/CookiesContext'
import {Meta} from 'src/common/Meta'
import {HubspotProvider} from 'next-hubspot'
import Intercom from '@intercom/messenger-js-sdk'
import {useEffectOnce} from 'react-use'
import {AnalyticsProvider} from 'src/context/AnalyticsContext'
import {OnRouteChangeTracker} from 'src/common/OnRouteChangeTracker'

const repositoryName = sm.repositoryName

const mainFont = localFont({
  display: 'swap',

  src: [
    {path: '../public/fonts/AeonikPro-Regular.woff2', weight: '400'},
    {path: '../public/fonts/AeonikPro-Medium.woff2', weight: '500'},
    {path: '../public/fonts/AeonikPro-Bold.woff2', weight: '700'},
  ],

  variable: '--font-main',
})

export default function App({Component, pageProps}: AppProps) {
  useEffectOnce(() => {
    if (process.env.NODE_ENV !== 'development') {
      Intercom({
        app_id: 'kvadbg23',
        hide_default_launcher: false,
        action_color: '#0C3A31',
        background_color: '#0C3A31',
      })
    }
  })

  return (
    <PrismicProvider
      // @ts-ignore -- `locale` should exist here, check if it's a type mistake
      internalLinkComponent={({href, children, locale, ...props}) => (
        <Link href={href} locale={locale}>
          <a {...props}>{children}</a>
        </Link>
      )}
    >
      <PrismicPreview repositoryName={repositoryName}>
        <style jsx global>
          {`
            html {
              --font-main: ${mainFont.style.fontFamily};
            }
          `}
        </style>

        <Meta>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta charSet="utf-8" />
          <link rel="icon" href="/favicon.ico" sizes="32x32" />
          <link rel="icon" href="/favicon/icon.svg" type="image/svg+xml" />
          <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png" />
          <link rel="manifest" href="/favicon/manifest.webmanifest" />
        </Meta>
        <AnalyticsProvider>
          <HubspotProvider>
            <CookiesProvider>
              <Component {...pageProps} />
            </CookiesProvider>
          </HubspotProvider>
          <OnRouteChangeTracker />
        </AnalyticsProvider>
      </PrismicPreview>
    </PrismicProvider>
  )
}
