import {AnalyticsBrowser} from '@dreamdata/analytics-next'
import {Dispatch, SetStateAction} from 'react'

const DREAM_DATA_SERVICE_ID = 'jE46pSyKb4wx8z'
const USERLED_SERVICE_ID = 'jCb-yiiH6wjwWi'

const UC_SETTINGS_KEY = 'uc_settings'
const ACCEPT_ALL_SERVICES = 'onAcceptAllServices'
const UPDATE_SERVICES = 'onUpdateServices'
const DECLINE_ALL_SERVICES = 'onDeclineAllServices'
const DREAM_DATA_KEY = 'a0980a5b-52fe-4e04-beed-2e8166512969'

interface UCSettings {
  services: {id: string; status: boolean}[]
}

export type UserledTracker = {
  page: (name?: string) => void
}

export const loadDreamdata = (
  dreamdataTracker: AnalyticsBrowser | null,
  setDreamdataLoaded: Dispatch<SetStateAction<boolean>>,
) => {
  const ucSettings: UCSettings = JSON.parse(localStorage.getItem(UC_SETTINGS_KEY)!)
  const dreamdataService = ucSettings?.services?.find((service: {id: string}) => service.id === DREAM_DATA_SERVICE_ID)

  // Load Dreamdata with all services disabled by default, then set individual
  // services based on user's settings. -- TODO: Use anonymous property to turn on cookieless tracking
  if (dreamdataTracker) {
    dreamdataTracker
      .load(
        {writeKey: DREAM_DATA_KEY},
        {
          anonymous: dreamdataService?.status,
        },
      )
      .catch((e) => {
        console.log(e)
      })
      .then(() => {
        setDreamdataLoaded(true)
      })
  }
}

const handleAcceptOrUpdateServices = ({
  dreamdataLoaded,
  loadDreamdataFn,
  userledTracker,
  loadUserledFn,
}: {
  dreamdataLoaded: boolean
  loadDreamdataFn: () => void
  userledTracker: UserledTracker | null
  loadUserledFn: () => void
}) => {
  if (dreamdataLoaded) {
    window.location.reload()
  } else {
    loadDreamdataFn()
  }

  if (!userledTracker) {
    loadUserledFn()
  }
}

const handleDeclineAllServices = ({
  dreamdataTracker,
  loadUserledFn,
}: {
  dreamdataTracker: AnalyticsBrowser | null
  loadUserledFn: () => void
}) => {
  dreamdataTracker?.reset()
  loadUserledFn()
}

export const reactToUsercentrics = ({
  event,
  dreamdataTracker,
  dreamdataLoaded,
  loadDreamdataFn,
  userledTracker,
  loadUserledFn,
}: {
  event: any
  dreamdataTracker: AnalyticsBrowser | null
  dreamdataLoaded: boolean
  loadDreamdataFn: () => void
  userledTracker: UserledTracker | null
  loadUserledFn: () => void
}) => {
  const action = event.detail.action

  if (action === ACCEPT_ALL_SERVICES || action === UPDATE_SERVICES) {
    handleAcceptOrUpdateServices({dreamdataLoaded, loadDreamdataFn, userledTracker, loadUserledFn})
  } else if (action === DECLINE_ALL_SERVICES) {
    handleDeclineAllServices({dreamdataTracker, loadUserledFn})
  }
}

export const loadUserled = (setUserledTracker: Dispatch<SetStateAction<UserledTracker | null>>) => {
  const ucSettings: UCSettings = JSON.parse(localStorage.getItem(UC_SETTINGS_KEY)!)
  const userledService = ucSettings?.services?.find((service: {id: string}) => service.id === USERLED_SERVICE_ID)

  if (userledService?.status) {
    const userledTracker: UserledTracker = {
      page: (name?: string) => {
        if (window?.Userled) {
          window.Userled('page', name)
        }
      },
    }

    return setUserledTracker(userledTracker)
  } else {
    setUserledTracker(null)
  }
}
