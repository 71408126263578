import {ReactNode, createContext, useCallback, useContext, useEffect, useState} from 'react'

const COOKIES_ACCEPTED_KEY = 'cookies-accepted'
export const MARKETING_COOKIES_KEY = 'marketing'
export const ANALYTICS_COOKIES_KEY = 'analytics'

export const setCookie = (name: string, value: string, maxAge = 2147483647, domain = '.getmoss.com') => {
  const cookieOptions = `domain=${domain};path=/;max-age=${maxAge}`
  document.cookie = `${name}=${encodeURIComponent(value)};${cookieOptions}`
}

const readCookie = (name: string) => {
  const nameEq = name + '='
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()

    if (cookie.indexOf(nameEq) === 0) {
      return decodeURIComponent(cookie.substring(nameEq.length, cookie.length))
    }
  }

  return null
}

type Cookie = {
  analytics: boolean
  marketing: boolean
}

const initCookies = (): Cookie | null => {
  const cookieValue = readCookie(COOKIES_ACCEPTED_KEY)
  const parsedCookiesAccepted = cookieValue ? JSON.parse(cookieValue) : null

  if (parsedCookiesAccepted === null) {
    return null
  } else if (parsedCookiesAccepted === true || parsedCookiesAccepted === false) {
    return {
      [MARKETING_COOKIES_KEY]: parsedCookiesAccepted,
      [ANALYTICS_COOKIES_KEY]: parsedCookiesAccepted,
    }
  } else {
    return parsedCookiesAccepted
  }
}

type Context = {
  cookiesAccepted: Cookie | null
  setCookiesAccepted: (cookie: Cookie | null) => void
}

const defaultValue: Context = {
  cookiesAccepted: null,
  setCookiesAccepted: () => null,
}

const CookiesContext = createContext<Context>(defaultValue)
export const useCookies = () => useContext(CookiesContext)

export const CookiesProvider = (props: {children: ReactNode}) => {
  const [cookiesAccepted, setCookiesAccepted] = useState<Cookie | null>(null)

  // Init cookies for local state
  useEffect(() => {
    if (!document) {
      return
    }

    setCookiesAccepted(initCookies())
  }, [])

  const setCookieAndLocalState = useCallback((newCookiesAccepted: Cookie | null) => {
    setCookie(COOKIES_ACCEPTED_KEY, JSON.stringify(newCookiesAccepted))
    setCookiesAccepted(newCookiesAccepted)
  }, [])

  return (
    <CookiesContext.Provider value={{cookiesAccepted, setCookiesAccepted: setCookieAndLocalState}}>
      {props.children}
    </CookiesContext.Provider>
  )
}
